export default class Entidade {
  constructor(
    id = null,
    nome = null,
    margemEmprestimo = null,
    margemCartao = null,
    margemBensDuraveis = null,
    margemAdiantamento = null,
    cargoResponsavel = null,
    orgaoResponsavel = null,
    responsavel = null,
    diaCorte = null,
    diaCobranca = null,
    ativo = true,
    quantidadeCosignacoesPorRubrica = null,
    processamentoForaMargem = false,
  ) {
    this.id = id
    this.nome = nome
    this.margemEmprestimo = margemEmprestimo
    this.margemCartao = margemCartao
    this.margemBensDuraveis = margemBensDuraveis
    this.margemAdiantamento = margemAdiantamento
    this.cargoResponsavel = cargoResponsavel
    this.orgaoResponsavel = orgaoResponsavel
    this.responsavel = responsavel
    this.diaCorte = diaCorte
    this.diaCobranca = diaCobranca
    this.ativo = ativo
    this.quantidadeCosignacoesPorRubrica = quantidadeCosignacoesPorRubrica
    this.processamentoForaMargem = processamentoForaMargem
  }
}
