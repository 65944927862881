<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-6 md:col-6">
              <label for="nome" class="required">Nome</label>
              <InputText
                id="nome"
                v-model="entidade.nome"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.entidade.nome.$invalid,
                }" />
              <div
                v-if="submitted && v$.entidade.nome.required.$invalid"
                class="p-error">
                O campo Nome é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="responsável">Responsável</label>
              <InputText
                id="responsável"
                v-model="entidade.responsavel"
                type="text" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="cargoResponsavel">Cargo Responsável</label>
              <InputText
                id="cargoResponsavel"
                v-model="entidade.cargoResponsavel"
                type="text" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="orgaoResponsavel">Órgão Responsável</label>
              <InputText
                id="orgaoResponsavel"
                v-model="entidade.orgaoResponsavel"
                type="text" />
            </div>

            <div class="inline-block col-6 md:col-6">
              <label for="margemEmprestimo" class="required"
                >Margem Empréstimo</label
              >
              <InputNumber
                id="margemEmprestimo"
                v-model="entidade.margemEmprestimo"
                :class="{
                  'p-invalid':
                    submitted && v$.entidade.margemEmprestimo.$invalid,
                }"
                suffix="%" />
              <div
                v-if="
                  submitted && v$.entidade.margemEmprestimo.required.$invalid
                "
                class="p-error">
                O campo Margem Emprestimo é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="margemCartao" class="required">Margem Cartão</label>
              <InputNumber
                id="margemCartao"
                v-model="entidade.margemCartao"
                :class="{
                  'p-invalid': submitted && v$.entidade.margemCartao.$invalid,
                }"
                suffix="%" />
              <div
                v-if="submitted && v$.entidade.margemCartao.required.$invalid"
                class="p-error">
                O campo Margem Cartão é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="margemBensDuraveis" class="required"
                >Margem Bem Duráveis</label
              >
              <InputNumber
                id="margemBensDuraveis"
                v-model="entidade.margemBensDuraveis"
                :class="{
                  'p-invalid':
                    submitted && v$.entidade.margemBensDuraveis.$invalid,
                }"
                suffix="%" />
              <div
                v-if="
                  submitted && v$.entidade.margemBensDuraveis.required.$invalid
                "
                class="p-error">
                O campo Margem Bens Duráveis é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="margemAdiantamento" class="required"
                >Margem Bem Adiantamento</label
              >
              <InputNumber
                id="margemAdiantamento"
                v-model="entidade.margemAdiantamento"
                :class="{
                  'p-invalid':
                    submitted && v$.entidade.margemAdiantamento.$invalid,
                }"
                suffix="%" />
              <div
                v-if="
                  submitted && v$.entidade.margemAdiantamento.required.$invalid
                "
                class="p-error">
                O campo Margem Adiantamento é obrigatório.
              </div>
            </div>

            <div class="inline-block col-6 md:col-6">
              <label for="diaCorte" class="required">Dia de Corte</label>
              <InputNumber
                id="diaCorte"
                v-model="entidade.diaCorte"
                :class="{
                  'p-invalid': submitted && v$.entidade.diaCorte.$invalid,
                }" />
              <div
                v-if="submitted && v$.entidade.diaCorte.required.$invalid"
                class="p-error">
                O campo Dia de Corte é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="diaCobranca" class="required">Dia de Cobrança</label>
              <InputNumber
                id="diaCobranca"
                v-model="entidade.diaCobranca"
                :class="{
                  'p-invalid': submitted && v$.entidade.diaCobranca.$invalid,
                }" />
              <div
                v-if="submitted && v$.entidade.diaCobranca.required.$invalid"
                class="p-error">
                O campo Dia de Cobrança é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="quantidadeCosignacoesPorRubrica" class="required"
                >Quantidade Consiganações por Rubrica</label
              >
              <InputNumber
                id="quantidadeCosignacoesPorRubrica"
                v-model="entidade.quantidadeCosignacoesPorRubrica"
                :class="{
                  'p-invalid':
                    submitted &&
                    v$.entidade.quantidadeCosignacoesPorRubrica.$invalid,
                }" />
              <div
                v-if="
                  submitted &&
                  v$.entidade.quantidadeCosignacoesPorRubrica.$invalid
                "
                class="p-error">
                O campo Quantidade Consiganações por Rubrica é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <div class="flex items-center mb-2">
                <label for="ativo" class="mr-2">Status</label>
                <InputSwitch id="ativo" v-model="entidade.ativo" />
              </div>
              <div class="flex items-center">
                <label for="processamentoForaMargem" class="mr-2"
                  >Processamento Fora de Margem</label
                >
                <InputSwitch
                  id="processamentoForaMargem"
                  v-model="entidade.processamentoForaMargem" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Entidade from '@/domain/Entidade.js'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsentidade: {
      type: Object,
      required: false,
      default: new Entidade(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      entidade: new Entidade(),
      saving: false,
      confirmDialog: false,
      submitted: false,
    }
  },

  validations() {
    return {
      entidade: {
        nome: { required },
        margemEmprestimo: { required },
        margemCartao: { required },
        margemBensDuraveis: { required },
        margemAdiantamento: { required },
        diaCorte: { required },
        diaCobranca: { required },
        quantidadeCosignacoesPorRubrica: { required },
      },
    }
  },

  created() {
    this.service = new EntidadeService(this.$http)
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Entidade' : 'Nova Entidade'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.entidade = JSON.parse(JSON.stringify(this.propsentidade))
    },

    onHide() {},

    validate() {
      this.submitted = true
      this.v$.entidade.$touch()

      return this.v$.entidade.$invalid ? '' : this.salvar()
    },

    salvar() {
      this.saving = true
      if (this.entidade.ativo == null) {
        this.entidade.ativo = false
      }
      this.service
        .saveEntidade(this.entidade)
        .then((res) => {
          this.$emit('onUpdate', res.data)
          if (this.entidade.id) {
            this.exibeToast('success', 'Entidade atualizada com sucesso')
          } else {
            this.exibeToast('success', 'Entidade cadastrada com sucesso')
          }
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
        .finally(() => {
          this.saving = false
          this.fechar()
        })
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}

.break-line {
  white-space: break-spaces;
}
</style>
