<template>
  <div>
    <Card>
      <template #title>Entidades</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="entidades"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['nome', 'orgaoResponsavel']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} entidades"
          responsiveLayout="scroll">
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormEntidade()" />
          </template>
          <template #empty> Nenhuma entidade encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="id" header="ID"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="orgaoResponsavel" header="Orgão Responsável"></Column>
          <Column
            field="ativo"
            header="Status"
            headerStyle="text-align: center">
            <template #body="slotProps">
              <Chip
                v-if="slotProps.data.ativo"
                label="ATIVO"
                class="mr-2 mb-2 custom-chip emUso" />
              <Chip
                v-else
                label="INATIVO"
                class="mr-2 mb-2 custom-chip foraUso" />
            </template>
          </Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Vínculos'"
                icon="pi pi-link"
                class="mt-2 mr-2 p-button-rounded p-button-info"
                @click="listaVinculos(slotProps.data)" />
              <Button
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="mt-2 mr-2 p-button-rounded p-button-warning"
                @click="showFormEntidade(slotProps.data)" />
              <Button
                v-tooltip.left="'Excluir'"
                icon="pi pi-trash"
                class="mt-2 mr-2 p-button-rounded p-button-danger"
                @click="confirmDeleteEntidade(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <Dialog
      modal
      header="Header"
      :style="{ width: '350px' }"
      :visible.sync="deleteEntidadeDialog">
      <template #header>
        <h3>Excluir Entidade</h3>
      </template>

      Confirma a exclusão de {{ entidade.nome }} ?

      <template #footer>
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteEntidadeDialog = false" />
        <Button
          label="Sim"
          icon="pi pi-check"
          autofocus
          @click="deleteEntidade" />
      </template>
    </Dialog>

    <EntidadeFormDialog
      :visible.sync="showEntidadeFormDialog"
      :propsentidade="entidade"
      :update="update"
      @onUpdate="onUpdateEntidade" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import EntidadeFormDialog from './EntidadeFormDialog.vue'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  components: {
    EntidadeFormDialog,
  },

  data() {
    return {
      entidades: null,
      entidade: {},
      deleteEntidadeDialog: false,
      filtros: {},
      loading: false,
      showEntidadeFormDialog: false,
      update: false,
    }
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    carregarDados() {
      this.loading = true
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
        this.loading = false
      })
    },

    confirmDeleteEntidade(data) {
      this.deleteEntidadeDialog = true
      this.entidade = data
    },

    showFormEntidade(data) {
      if (data) {
        this.entidade = data
        this.update = true
      } else {
        this.entidade = {}
        this.update = false
      }
      this.showEntidadeFormDialog = true
    },

    onUpdateEntidade(event) {
      let index = this.entidades.findIndex((el) => el.id === event.id)
      index != -1
        ? this.$set(this.entidades, index, event)
        : this.entidades.unshift(event)
    },

    deleteEntidade() {
      this.deleteEntidadeDialog = false
      this.entidadeService
        .deleteEntidade(this.entidade)
        .then(() => {
          this.carregarDados()
          this.entidade = {}
          this.$toast.add({
            severity: 'success',
            summary: 'Excluido com sucesso!',
            life: 10000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    listaVinculos(data) {
      this.$router.push({
        name: 'entidade-vinculos',
        params: { id: data.id, entidade: data },
      })
    },
  },
}
</script>

<style>
.foraUso {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}
.emUso {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}
</style>
